// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #005baa;
$secondary: #ffe600;
$accent: #ffe500;
$positive: #21ba45;
$negative: #c10015;
$info: #31ccec;
$warning: #f2c037;
$font-color: #3c3c43;
$title-color: #363b3f;

$michelin-background-color: #005baa;
$michein-text: #ffffff;
$bfg-background-color: #003366;
$bfg-text: #ffffff;
$uniroyal-background-color: #c10015;
$uniroyal-text: #ffffff;

@mixin common-shadow {
  box-shadow: 0 6px 10px 2px rgba(0, 5, 10, 0.1);
}
